import { Badge, Banner } from "@shopify/polaris";
import React from "react";
import styled from "styled-components";

// For consistency with AccountPlan.tsx we use few css classes from AccountPlans.css
import "./AccountPlans.css";
import { AccountPlanStatusEnum, IAccountPlan } from "@smartrr/shared/entities/AccountPlan";
import { ClockMajor } from "@shopify/polaris-icons";
interface Props {
  freeTrialPlan: IAccountPlan;
}

/*
In styles below width of 903 is derived from: 3 * widths of cards + 2 spaces between the cards.
width of card .Polaris-Layout__Section cards is 281px
space between the cards is 30px
*/

const FreeTrialBannerWrapper = styled.div`
  margin-bottom: 2rem;
  .Polaris-Badge {
    position: absolute;
    right: 20px;
    top: 20px;
  }
`;

export function FreeTrialPlan({ freeTrialPlan }: Props): JSX.Element {
  const isExpired = freeTrialPlan.status === AccountPlanStatusEnum.EXPIRED_TRIAL;
  const badgeMarkup = `${freeTrialPlan.trialDays ?? 0} days`;
  if (isExpired) {
    return (
      <FreeTrialBannerWrapper>
        <Banner title="Your free trial has expired" status="warning">
          <p>Please select from one of the plans below to continue using Smartrr.</p>
        </Banner>
      </FreeTrialBannerWrapper>
    );
  }

  return (
    <FreeTrialBannerWrapper>
      <Banner title="Free trial" icon={ClockMajor} status="info">
        <Badge status="info">{badgeMarkup}</Badge>
        <p>
          Heads up: you currently have access to all features in our 📈 Grow plan on your free trial (except
          prepaid subscriptions).
        </p>
      </Banner>
    </FreeTrialBannerWrapper>
  );
}
