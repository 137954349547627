import { Badge, Button, Card, HorizontalStack, Text, VerticalStack } from "@shopify/polaris";
import { IEmailPreview } from "@smartrr/shared/entities/CustomerEmailConfig/types";
import React from "react";

export interface MerchantEmailCardProps extends IEmailPreview {
  key: string;
  onToggle: () => void;
  onPreview: () => void;
}

export const MerchantEmailCard = ({
  title,
  description,
  enabled,
  preview = true,
  onPreview,
  onToggle,
}: MerchantEmailCardProps) => {
  return (
    <Card>
      {enabled}
      <VerticalStack gap="2">
        <HorizontalStack align="space-between">
          <HorizontalStack gap="1" blockAlign="center">
            <Text as="h2" variant="headingSm">
              {title}
            </Text>
            <Badge status={enabled ? "success" : undefined}>{enabled ? "On" : "Off"}</Badge>
          </HorizontalStack>
          <HorizontalStack gap="2">
            <Button accessibilityLabel={enabled ? "Turn off email" : "Turn on email"} onClick={onToggle}>
              {enabled ? "Turn off" : "Turn on"}
            </Button>
            {preview ? (
              <Button primary accessibilityLabel="Preview email" onClick={onPreview}>
                Preview
              </Button>
            ) : (
              <React.Fragment />
            )}
          </HorizontalStack>
        </HorizontalStack>
        <Text as="p" variant="bodyMd">
          {description}
        </Text>
      </VerticalStack>
    </Card>
  );
};
