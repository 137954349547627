import { createLazyFileRoute } from "@tanstack/react-router";

import { ErrorBoundary } from "@vendor-app/app/_sharedComponents/ErrorBoundary";
import { AdminSmartrrAccountRoute } from "@vendor-app/app/AdminRoute/AdminSmartrrAccount";

export const Route = createLazyFileRoute("/admin/smartrr-account")({
  component: Index,
  errorComponent: ({ error, info }) => <ErrorBoundary error={error} info={info} />,
});

function Index() {
  return <AdminSmartrrAccountRoute />;
}
