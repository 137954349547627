import { Banner, Button, HorizontalStack, Text, VerticalStack } from "@shopify/polaris";
import React from "react";

export function DowngradeWarning({
  setIsDowngrading,
  setConfirmDownGrading,
}: {
  setConfirmDownGrading: React.Dispatch<React.SetStateAction<boolean>>;
  setIsDowngrading: React.Dispatch<React.SetStateAction<boolean>>;
}): JSX.Element {
  return (
    <Banner title="Downgrade warning" status="critical">
      <VerticalStack gap={"3"}>
        <Text variant="bodyMd" as="p">
          By downgrading, you&apos;ll lose access to some of your existing features. Please confirm you&apos;d
          like to continue.
        </Text>
        <HorizontalStack gap={"4"}>
          <Button
            destructive
            onClick={() => {
              setConfirmDownGrading(true);
            }}
          >
            Confirm Downgrade
          </Button>
          <Button
            primary
            onClick={() => {
              setIsDowngrading(false);
            }}
          >
            Keep current plan
          </Button>
        </HorizontalStack>
      </VerticalStack>
    </Banner>
  );
}
