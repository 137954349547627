import { LegacyStack, Tabs, Page, Spinner } from "@shopify/polaris";
import React, { useEffect, useMemo, useState } from "react";

import { loadAccountPlanConfigs, syncAccountPlans } from "@vendor-app/app/_state/actionCreators/accountPlans";
import { useSmartrrVendorDispatch, useSmartrrVendorSelector } from "@vendor-app/app/_state/typedVendorReduxHooks";

import { AccountPlanSelection } from "./components/AccountPlanSelection";
import { EmailNotifications } from "./components/EmailNotifications";
import styled from "styled-components";

const PageWrapper = styled.div`
  .Polaris-Page {
    padding: 0;
    margin: 0 auto;
  }
`;

enum AccountActionTabs {
  ACCOUNT_PLAN,
  EMAIL_NOTIFICATIONS,
}

export function AdminSmartrrAccountRoute(): JSX.Element {
  const [selectedTab, setSelectedTab] = useState(AccountActionTabs.ACCOUNT_PLAN);
  const dispatch = useSmartrrVendorDispatch();

  const isSyncingPlans = useSmartrrVendorSelector(state => state.accountPlans.isSyncingPlans);
  const isLoadingConfigs = useSmartrrVendorSelector(state => state.accountPlans.isLoadingConfigs);

  const tabs = useMemo(
    () => [
      { id: "account-plan", content: "Account plan" },
      { id: "email-notifications", content: "Email notifications" },
    ],
    []
  );

  // We always load latest state of plans from Shopify on this page
  // We can sync plans and load configs simultaneously at the moment, later we might need to do this sequentially
  useEffect(() => {
    dispatch(syncAccountPlans());
    dispatch(loadAccountPlanConfigs());
  }, []);

  return (
    <PageWrapper>
      <Page>
        <Tabs tabs={tabs} selected={selectedTab} onSelect={setSelectedTab} fitted>
          <AccountTabsContent isLoading={isSyncingPlans || isLoadingConfigs} selectedTab={selectedTab} />
        </Tabs>
      </Page>
    </PageWrapper>
  );
}

const AccountTabsContent = ({
  isLoading,
  selectedTab,
}: {
  isLoading: boolean;
  selectedTab: AccountActionTabs;
}) => {
  if (isLoading) {
    return (
      <LegacyStack alignment="center" distribution="center">
        <Spinner />
      </LegacyStack>
    );
  }

  switch (selectedTab) {
    case AccountActionTabs.ACCOUNT_PLAN: {
      return <AccountPlanSelection />;
    }
    case AccountActionTabs.EMAIL_NOTIFICATIONS: {
      return <EmailNotifications />;
    }
    default: {
      return <React.Fragment />;
    }
  }
};
