import React, { useCallback, useEffect } from "react";
import { useSmartrrVendorSelector } from "@vendor-app/app/_state/typedVendorReduxHooks";
import useGenerateEmailConfigOptions from "../../../AdminSubscriberEngagementRoute/EmailSettings/components/libs/hooks/useGenerateEmailConfigOptions";
import { HorizontalStack, Page, Spinner, Text, VerticalStack } from "@shopify/polaris";
import { EmailNotificationPreviewModal } from "@vendor-app/app/_sharedComponents/NotificationEmails/EmailPreviewModal";
import { useEmailPreviewModalStore } from "@vendor-app/app/_sharedComponents/NotificationEmails/EmailPreviewModal/lib/useEmailPreviewModalStore";
import { NotificationEmails } from "@smartrr/shared/entities/CustomerEmailConfig";
import { useToast } from "@vendor-app/app/_sharedComponents/Toast/ToastProvider";
import { MerchantCardSections } from "./components/MerchantCardSections";
import { useIsSuperUserSelector } from "@vendor-app/app/_state/reducers/auth";
import { EmailNotificationAccessStore } from "@vendor-app/app/_state/zustand/EmailNotificationsStore";
import { noawait } from "@smartrr/shared/utils/noawait";
import retry from "async-retry";

export const EmailNotifications = () => {
  const { addToast } = useToast();
  const { errorMessage, showError } = EmailNotificationAccessStore.useErrors();
  const { initialize: initializeEmailNotifications } = EmailNotificationAccessStore.useActions();
  const isLoading = EmailNotificationAccessStore.useLoading();
  const { emailConfigs } = EmailNotificationAccessStore.useEmailConfigs();

  useEffect(() => {
    noawait(async () => await retry(initializeEmailNotifications, { retries: 3 }));
  }, [!emailConfigs]);

  useEffect(() => {
    if (showError && errorMessage) {
      addToast(errorMessage, true);
    }
  }, [showError]);

  if (isLoading || !emailConfigs) {
    return (
      <HorizontalStack align="center">
        <Spinner />
      </HorizontalStack>
    );
  }

  return <EmailNotificationsInner />;
};

const EmailNotificationsInner = () => {
  const userEmail = useSmartrrVendorSelector(state => state.auth.user?.originalEmail);
  const emailConfigFormattedRecord = useGenerateEmailConfigOptions({
    defaults: false,
    emailRecipient: "merchant",
  });
  const { update: updateEmailNotification } = EmailNotificationAccessStore.useActions();
  const { emailConfigs } = EmailNotificationAccessStore.useEmailConfigs();
  const closeModal = useEmailPreviewModalStore(state => state.closeModal);
  const isModalOpen = useEmailPreviewModalStore(state => state.isModalOpen);
  const openModal = useEmailPreviewModalStore(state => state.openModal);
  const isSuperUser = useIsSuperUserSelector();

  const onPreview = useCallback(
    (previewingEmail: NotificationEmails) => {
      if (isModalOpen) {
        closeModal();
        return;
      }
      const emailPreview = emailConfigFormattedRecord[previewingEmail];
      openModal({
        emailPreview,
        formValues: undefined,
        ableToSendPreview: isSuperUser,
        defaults: true,
      });
    },
    [isModalOpen]
  );

  const onToggle = useCallback(
    async (previewingEmail: NotificationEmails) => {
      if (!emailConfigs) {
        return;
      }

      updateEmailNotification({
        ...emailConfigs,
        [emailConfigFormattedRecord[previewingEmail].configValues?.toggle as string]:
          !emailConfigFormattedRecord[previewingEmail].enabled,
      });
      //TODO: a success toast can be added here if the action succeeds
    },
    [emailConfigs, emailConfigFormattedRecord]
  );

  return (
    <React.Fragment>
      <Page narrowWidth>
        <VerticalStack gap="5">
          <Text as="p" variant="bodyMd" color="subdued">
            Sending to: {userEmail}
          </Text>
          <MerchantCardSections onPreview={onPreview} onToggle={onToggle} />
        </VerticalStack>
      </Page>
      <EmailNotificationPreviewModal />
    </React.Fragment>
  );
};
