import { HorizontalStack, Spinner, Text, VerticalStack } from "@shopify/polaris";
import React from "react";
import { MerchantEmailCard } from "../MerchantEmailCard";
import { NotificationEmails } from "@smartrr/shared/entities/CustomerEmailConfig";
import { EmailNotificationAccessStore } from "@vendor-app/app/_state/zustand/EmailNotificationsStore";
import { useEmails } from "@vendor-app/app/AdminRoute/AdminSubscriberEngagementRoute/EmailSettings/components/libs/hooks/useEmails";

export const MerchantCardSections = ({
  onPreview,
  onToggle,
}: {
  onPreview: (previewingEmail: NotificationEmails) => void;
  onToggle: (previewingEmail: NotificationEmails) => void;
}) => {
  const isLoading = EmailNotificationAccessStore.useLoading();
  const { formattedSectionsWithEmails } = useEmails({ defaults: false, emailRecipient: "merchant" });

  if (isLoading) {
    return (
      <HorizontalStack align="center">
        <Spinner />
      </HorizontalStack>
    );
  }

  return (
    <React.Fragment>
      {formattedSectionsWithEmails.map(({ section, emails }) => (
        <VerticalStack gap="5" key={section.section}>
          <Text as="h3" variant="bodyLg">
            {section.title}
          </Text>
          {emails.map(emailObj => {
            const [emailType, emailConfig] = Object.entries(emailObj)[0];
            return (
              <MerchantEmailCard
                key={emailType}
                {...emailConfig}
                onPreview={() => onPreview(emailType as NotificationEmails)}
                onToggle={() => onToggle(emailType as NotificationEmails)}
              />
            );
          })}
        </VerticalStack>
      ))}
    </React.Fragment>
  );
};
