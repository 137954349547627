import { Layout, LegacyCard, Link, Page, VerticalStack } from "@shopify/polaris";
import React, { useState } from "react";

import { useSmartrrVendorSelector } from "@vendor-app/app/_state/typedVendorReduxHooks";

import "./AccountPlans.css";
import { AccountPlan } from "./AccountPlan";
import { DowngradeWarning } from "./DowngradeWarning";
import { FreeTrialPlan } from "./FreeTrialPlan";
import { RedirectFrame } from "./RedirectFrame";

import styled from "styled-components";

const RedirectWrapper = styled.div`
  & .Polaris-LegacyCard {
    align-items: center;
    display: flex;
    flex-direction: column;
    height: max-content;
    justify-content: center;
    min-height: 27rem;
    min-width: 954px;
    & svg {
      margin-bottom: 8px;
    }
    & p,
    button,
    a {
      color: #6d7175 !important;
    }
  }
`;

const AccountPlanPage = styled.div`
  display: flex;
  justify-content: center;
`;

// In final iteration we can move this to backend and call API that returns configs with descriptions
const PLAN_DESCRIPTIONS = [
  {
    planName: "Launch",
    planIcon: "🚀",
    isPlanFeeFixed: true,
    featuresDescription: [
      "Recurring payments for Shopify subscription orders",
      "Branded Shopify checkout & customer account portal",
      "Automated customer SMS & email notifications",
      "Dedicated subscription shipping profiles",
      "Subscription management (skip, gift, order now)",
      "24/7 in-app support",
    ],
    integrationsDescription: [],
  },
  {
    planName: "Grow",
    planIcon: "📈",
    isPlanFeeFixed: true,
    featuresDescription: [
      "All Launch features +",
      " ",
      "Named Customer Success Manager",
      "Prepaid subscriptions",
      "Sequential subscriptions",
      "Multi-shop setup for multi-locale websites",
      "Smart retention, pause, and cancellation flows",
      "Supported migrations from Recharge, Bold, Stay.ai, Skio, Prive and more",
    ],
    integrationsDescription: [
      "Klaviyo, Gorgias, Postscript, Littledata, Rebuy, Attentive + more",
      "Advanced analytics powered by Looker",
    ],
  },
  {
    planName: "Excel",
    planIcon: "💎",
    isPlanFeeFixed: false,
    featuresDescription: [
      "All Grow features +",
      " ",
      "Loyalty rewards & referrals",
      'Advanced bundles (including "Build-a-box")',
      "Advanced sequencing (Coming soon)",
      "Passwordless login",
      "Digital subscriptions",
      "Tiered pricing",
      "Smart retention",
      "Dedicated migration team for upgrading to Smartrr from legacy subscription platforms",
    ],
    integrationsDescription: ["Custom built reporting", "Private Slack channels"],
  },
];

export function AccountPlanSelection(): JSX.Element {
  const configs = useSmartrrVendorSelector(state => state.accountPlans.configs);
  const activePlan = useSmartrrVendorSelector(state => state.accountPlans.activePlan);
  const freeTrialPlan = useSmartrrVendorSelector(state => state.accountPlans.freeTrialPlan);
  const pendingPlan = useSmartrrVendorSelector(state => state.accountPlans.pendingPlan);
  const [redirectToShopify, setRedirectToShopify] = useState<boolean>(false);
  const [isDowngrading, setIsDownGrading] = useState<boolean>(false); //opens banner
  const [confirmDowngrading, setConfirmDownGrading] = useState<boolean>(false); //confirmation
  const [downgradePlan, setDowngradePlan] = useState<string>(""); //
  if (redirectToShopify) {
    return (
      <RedirectWrapper>
        <LegacyCard>
          <RedirectFrame />
          <p>
            If you are not automatically redirected, <Link url={pendingPlan?.confirmationUrl}>click here</Link> or
            check to see if your pop-ups have been blocked.
          </p>
        </LegacyCard>
      </RedirectWrapper>
    );
  }

  return (
    <AccountPlanPage className="account-plan__page">
      <Page fullWidth title="Account" subtitle="Upgrade or downgrade your account plan below:">
        <VerticalStack gap={"5"}>
          {(freeTrialPlan && <FreeTrialPlan freeTrialPlan={freeTrialPlan} />) ||
            (isDowngrading && (
              <DowngradeWarning
                setIsDowngrading={setIsDownGrading}
                setConfirmDownGrading={setConfirmDownGrading}
              />
            ))}
          <Layout>
            {configs.map(config => {
              const currentPlanDescription = PLAN_DESCRIPTIONS.find(d => d.planName === config.planName);

              return (
                <AccountPlan
                  key={config.planName}
                  planName={config.planName}
                  planFee={config.planFee}
                  activePlanFee={activePlan?.planFee}
                  planIcon={currentPlanDescription!.planIcon}
                  percentageFee={config.percentageFee}
                  isPlanFeeFixed={currentPlanDescription!.isPlanFeeFixed}
                  trialDays={config.trialDays}
                  featuresDescription={currentPlanDescription!.featuresDescription}
                  integrationsDescription={currentPlanDescription!.integrationsDescription}
                  trialPeriodEnd={activePlan?.trialPeriodEnd}
                  hasActivePlan={!!activePlan}
                  isCurrent={config.planName === activePlan?.planName}
                  isLocked={config.isLocked}
                  isPending={config.planName === pendingPlan?.planName}
                  pendingConfirmationUrl={
                    config.planName === pendingPlan?.planName ? pendingPlan.confirmationUrl : undefined
                  }
                  setRedirectToShopify={setRedirectToShopify}
                  setIsDownGrading={setIsDownGrading}
                  confirmDowngrading={confirmDowngrading}
                  setDowngradePlan={setDowngradePlan}
                  downgradePlan={downgradePlan}
                />
              );
            })}
          </Layout>
        </VerticalStack>
      </Page>
    </AccountPlanPage>
  );
}
